<template>
  <div id="trustedShopsCheckout" style="display: none">
    <span id="tsCheckoutOrderNr">{{ orderDetails.orderNumber }}</span>
    <span id="tsCheckoutBuyerEmail">{{
      orderDetails.orderCustomer.email
    }}</span>
    <span id="tsCheckoutOrderAmount">{{ orderDetails.price.totalPrice }}</span>
    <span id="tsCheckoutOrderCurrency">{{
      (currency && currency.isoCode) || 'EUR'
    }}</span>
    <span id="tsCheckoutOrderPaymentType">{{ paymentName }}</span>
    <span id="tsCheckoutOrderEstDeliveryDate">{{ deliveryDate }}</span>
  </div>
</template>

<script>
import { useSessionContext } from '~/composables'

export default {
  name: 'PluginTrustedShopCheckout',
  props: {
    orderDetails: {
      type: Object,
      default: null,
    },
  },
  setup(_, { root }) {
    const { currency } = useSessionContext(root)

    return {
      currency,
    }
  },
  computed: {
    paymentName() {
      return this.orderDetails.transactions?.[0].paymentMethod?.translated.name
    },
    deliveryDate() {
      const d = this.orderDetails.deliveries?.[0].shippingDateEarliest

      return d ? d.substr(0, 10) : undefined
    },
  },
  mounted() {
    if (window.trustbadge) {
      window.trustbadge.reInitialize()
    }
  },
  destroyed() {
    if (window.trustbadge) {
      window.trustbadge.remove()
    }
  },
}
</script>
